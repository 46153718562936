import { css } from "styled-components"

import { lineHeightCrop } from "./theme.helper"

/*
  colors are used for some blog components - 
  do not delete until finishing updating 
  colors with new colors
*/
const colors = {
  black: "#000000",
  darkGrey: "#373F49",
  grey: "#636363",
  midGrey: "#D9D9D9",
  formGrey: "#949494",
  lightGrey: "#F6F6FB",
  white: "#FFFFFF",
  accentPink: "#F51767",
  lightPink: "#FEE8F0",
  accentPinkDarkened: "#BB2058",
  darkBlue: "#130FEB",
  accentBlue: "#4C0FFB",
  accentBlueDarkened: "#3300E2",
  accentSkyBlue: "#9BF9FF",
  accentGreen: "#32DD88",
  errorRed: "#EB000E",
  successGreen: "#1C8000",
  alertBlue: "#17054B",
  grey1: "#1A1A1A",
  hyperLinkPink: "#E60A5A",
  buttonLinkPink: "#E60A5A",
  buttonLinkPinkHover: "#C4084C",
}

const fontWeight = {
  light: 300,
  regular: 400,
  semiBold: 600,
  bold: 700,
}

const breakpoints = ["480px", "780px", "1000px", "1280px"]

export default {
  colors,
  fontWeight,
  breakpoints,
  typography: {
    desktop: {
      primaryCTA: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.bold};
        font-size: 65px;
        line-height: 80px;
        text-transform: uppercase;
        color: ${colors.black};
        ${lineHeightCrop(19, 14, 65, 80, "bold")};
      `,
      h1: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.bold};
        font-size: 50px;
        line-height: 65px;
        color: ${colors.black};
        ${lineHeightCrop(13, 7, 50, 65, "bold")};
      `,
      h2: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.bold};
        font-size: 40px;
        line-height: 50px;
        color: ${colors.black};
        ${lineHeightCrop(12, 9, 40, 50, "bold")};
      `,
      h3: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.bold};
        font-size: 32px;
        line-height: 42px;
        color: ${colors.black};
        ${lineHeightCrop(11, 8, 32, 42, "bold")};
      `,
      h4: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.bold};
        font-size: 26px;
        line-height: 36px;
        color: ${colors.black};
        ${lineHeightCrop(10, 7, 26, 36, "bold")};
      `,
      h5: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.bold};
        font-size: 22px;
        line-height: 32px;
        color: ${colors.black};
        ${lineHeightCrop(9, 7, 22, 32, "bold")};
      `,
      h6: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.bold};
        font-size: 18px;
        line-height: 26px;
        color: ${colors.black};
        ${lineHeightCrop(6, 6, 18, 26, "bold")};
      `,
      inputField: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.regular};
        font-size: 18px;
        line-height: 24px;
        color: ${colors.black};
        ${lineHeightCrop(6, 6, 18, 24)};
      `,
      link: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.bold};
        font-size: 20px;
        line-height: 30px;
        color: ${colors.accentPink};
        ${lineHeightCrop(9, 8, 20, 30, "bold")};
      `,
      blogInlineCTA: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.light};
        font-size: 32px;
        line-height: 42px;
        color: ${colors.black};
        ${lineHeightCrop(11, 8, 32, 42, "light")};
      `,
      largeInlineCTA: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.light};
        font-size: 28px;
        line-height: 38px;
        ${lineHeightCrop(11, 9, 28, 38, "light")};
      `,
      inlineCTA: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.light};
        font-size: 22px;
        line-height: 32px;
        ${lineHeightCrop(9, 7, 22, 32, "light")};
      `,
      body: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.light};
        font-size: 20px;
        line-height: 30px;
        ${lineHeightCrop(9, 8, 20, 30, "light")};
      `,
      excerpt: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.light};
        font-size: 16px;
        line-height: 24px;
        color: ${colors.darkGrey};
        ${lineHeightCrop(7, 6, 16, 24, "light")};
      `,
      metadata: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.regular};
        font-size: 14px;
        line-height: 21px;
        color: ${colors.grey};
        ${lineHeightCrop(6, 5, 14, 21)};
      `,
      validation: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.regular};
        font-size: 13px;
        line-height: 18px;
        ${lineHeightCrop(4, 4, 13, 18)};
      `,
      footer_link: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.regular};
        font-size: 20px;
        line-height: 35px;
        ${lineHeightCrop(4, 4, 13, 18)};
      `,
    },
    mobile: {
      primaryCTA: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.bold};
        font-size: 42px;
        line-height: 54px;
        text-transform: uppercase;
        color: ${colors.black};
        ${lineHeightCrop(12, 9, 42, 54, "bold")};
      `,
      h1: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.bold};
        font-size: 42px;
        line-height: 54px;
        color: ${colors.black};
        ${lineHeightCrop(12, 9, 42, 54, "bold")};
      `,
      h2: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.bold};
        font-size: 36px;
        line-height: 46px;
        color: ${colors.black};
        ${lineHeightCrop(11, 8, 36, 46, "bold")};
      `,
      h3: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.bold};
        font-size: 28px;
        line-height: 38px;
        color: ${colors.black};
        ${lineHeightCrop(11, 9, 28, 38, "bold")};
      `,
      h4: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.bold};
        font-size: 26px;
        line-height: 36px;
        color: ${colors.black};
        ${lineHeightCrop(10, 7, 26, 36, "bold")};
      `,
      h5: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.bold};
        font-size: 20px;
        line-height: 30px;
        color: ${colors.black};
        ${lineHeightCrop(9, 8, 20, 30, "bold")};
      `,
      h6: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.bold};
        font-size: 18px;
        line-height: 26px;
        color: ${colors.black};
        ${lineHeightCrop(6, 6, 18, 26, "bold")};
      `,
      inputField: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.regular};
        font-size: 18px;
        line-height: 24px;
        color: ${colors.black};
        ${lineHeightCrop(6, 6, 18, 24)};
      `,
      link: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.bold};
        font-size: 20px;
        line-height: 30px;
        color: ${colors.accentPink};
        ${lineHeightCrop(9, 8, 20, 30, "bold")};
      `,
      blogInlineCTA: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.light};
        font-size: 24px;
        line-height: 34px;
        color: ${colors.black};
        ${lineHeightCrop(9, 7, 24, 34, "light")};
      `,
      largeInlineCTA: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.light};
        font-size: 20px;
        line-height: 30px;
        ${lineHeightCrop(9, 8, 20, 30, "light")};
      `,
      inlineCTA: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.light};
        font-size: 20px;
        line-height: 30px;
        ${lineHeightCrop(9, 8, 20, 30, "light")};
      `,
      body: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.light};
        font-size: 18px;
        line-height: 28px;
        ${lineHeightCrop(7, 7, 20, 30, "light")};
      `,
      excerpt: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.light};
        font-size: 15px;
        line-height: 24px;
        color: ${colors.darkGrey};
        ${lineHeightCrop(7, 6, 15, 24, "light")};
      `,
      metadata: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.regular};
        font-size: 13px;
        line-height: 18px;
        color: ${colors.grey};
        ${lineHeightCrop(4, 4, 13, 18)};
      `,
      validation: css`
        font-family: Barlow, sans-serif;
        font-weight: ${fontWeight.regular};
        font-size: 13px;
        line-height: 18px;
        ${lineHeightCrop(4, 4, 13, 18)};
      `,
    },
  },
}
