import React, { useState, useEffect, createContext } from "react"

const PinnedCTAContext = createContext({
  hidePinnedCTA: false,
})

export const PinnedCTAProvider = ({ children }) => {
  const [hidePinnedCTA, setHidePinnedCTA] = useState(false)

  const onWindowSizeChange = () => {
    window.innerWidth > 760 ? setHidePinnedCTA(false) : setHidePinnedCTA(true)
  }

  useEffect(() => {
    onWindowSizeChange()

    window.addEventListener("resize", onWindowSizeChange)
    return () => {
      window.removeEventListener("resize", onWindowSizeChange)
    }
  }, [])

  return (
    <PinnedCTAContext.Provider
      value={{
        hidePinnedCTA,
      }}
    >
      {children}
    </PinnedCTAContext.Provider>
  )
}

export default PinnedCTAContext
