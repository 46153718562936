import React, { useState, createContext } from "react"

const BlogSidebarContext = createContext({
  isFormatOpen: true,
  isTopicOpen: true,
  isSubtopicOpen: true,
  isCourseLevelOpen: true,
  isCourseFormatOpen: true
})

export const BlogSidebarProvider = ({ children }) => {
  const [isTopicOpen, setIsTopicOpen] = useState(true)
  const [isSubtopicOpen, setIsSubtopicOpen] = useState(true)
  const [isFormatOpen, setIsFormatOpen] = useState(true)
  const [isCourseLevelOpen, setIsCourseLevelOpen] = useState(true)
  const [isCourseFormatOpen, setIsCourseFormatOpen] = useState(true)

  const toggleTopic = () => setIsTopicOpen(!isTopicOpen)
  const toggleSubtopic = () => setIsSubtopicOpen(!isSubtopicOpen)
  const toggleFormat = () => setIsFormatOpen(!isFormatOpen)
  const toggleCourseLevel = () => setIsCourseLevelOpen(!isCourseLevelOpen)
  const toggleCourseFormat = () => setIsCourseFormatOpen(!isCourseFormatOpen)

  return (
    <BlogSidebarContext.Provider
      value={{
        isFormatOpen,
        isTopicOpen,
        isSubtopicOpen,
        isCourseLevelOpen,
        isCourseFormatOpen,
        toggleFormat,
        toggleTopic,
        toggleSubtopic,
        toggleCourseLevel,
        toggleCourseFormat
      }}
    >
      {children}
    </BlogSidebarContext.Provider>
  )
}

export default BlogSidebarContext
