import styled from "styled-components"

export const YoutubePlayerContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;

  & iframe {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 780px) {
    position: relative;
    width: 100%;
    padding-bottom: 57.25%;
    height: 0;

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`
