import React, { createContext, useState } from "react"
const ShortPathContext = createContext({ shortPath: false })

export const ShortPathProvider = ({ children }) => {
  const [shortPath, setShortPath] = useState(false)
  return (
    <ShortPathContext.Provider value={{ shortPath, setShortPath }}>
      {children}
    </ShortPathContext.Provider>
  )
}

export default ShortPathContext
