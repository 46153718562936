import React, { createContext, useReducer, useMemo } from "react"

const AlertBannerActionTypes = {
  OPEN_ALERT_BANNER: "OPEN_ALERT_BANNER",
  CLOSE_ALERT_BANNER: "CLOSE_ALERT_BANNER",
}

const alertBannerReducer = (state, action) => {
  switch (action.type) {
    case AlertBannerActionTypes.OPEN_ALERT_BANNER:
      return {
        ...state,
        alertBannerIsOpen: true,
        text: action.payload.content,
        isStickyEnabled: action.payload.isStickyEnabled,
        bannerEndDate: action.payload.bannerEndDate,
        countdownEnabled: action.payload.countdownEnabled,
      }
    case AlertBannerActionTypes.CLOSE_ALERT_BANNER:
      return {
        ...state,
        alertBannerIsOpen: false,
        text: action.payload ? action.payload.content : null,
        isStickyEnabled: action.payload ? action.payload.isStickyEnabled : null,
        bannerEndDate: action.payload ? action.payload.bannerEndDate : null,
        countdownEnabled: action.payload
          ? action.payload.countdownEnabled
          : null,
      }
    default:
      return state
  }
}

const alertBannerInitialState = {
  alertBannerIsOpen: false,
  text: "",
  isStickyEnabled: true,
}

const AlertBannerContext = createContext({
  openAlertBanner: () => { },
  closeAlertBanner: () => { },
  alertBannerState: alertBannerInitialState,
})

export const AlertBannerProvider = ({ children }) => {
  const [alertBannerState, dispatch] = useReducer(
    alertBannerReducer,
    alertBannerInitialState
  )

  const openAlertBanner = payload =>
    dispatch({
      type: AlertBannerActionTypes.OPEN_ALERT_BANNER,
      payload,
    })

  const closeAlertBanner = payload =>
    dispatch({ type: AlertBannerActionTypes.CLOSE_ALERT_BANNER, payload })

  const value = useMemo(
    () => ({
      alertBannerState,
      openAlertBanner,
      closeAlertBanner,
      AlertBannerActionTypes,
    }),
    [alertBannerState]
  )



  return (
    <AlertBannerContext.Provider value={value}>
      {children}
    </AlertBannerContext.Provider>
  )
}

export default AlertBannerContext
