import React, { useContext } from "react"
import ModalContext from "../../providers/modal/modal.provider"
import { YoutubePlayerContainer } from "./video-player.styles"
import {
  ModalContentContainer,
  CloseButton,
  Overlay,
  ModalContainer,
} from "../modal/modal.styles"
const VideoPlayer = ({ videoUrl }) => {
  const { closeModal } = useContext(ModalContext)
  return (
    <ModalContainer>
      <Overlay onClick={() => closeModal()} />
      <ModalContentContainer>
        <CloseButton onClick={() => closeModal()}> &#10005; </CloseButton>
        <YoutubePlayerContainer>
          <iframe
            src={`https://www.youtube.com/embed/${videoUrl}?autoplay=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </YoutubePlayerContainer>
      </ModalContentContainer>
    </ModalContainer>
  )
}

export default VideoPlayer
