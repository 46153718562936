import React, { createContext, useReducer, useMemo } from "react"

const ModalActionTypes = {
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
}

const modalKeyMap = {
  VIDEO_PLAYER: "VIDEO_PLAYER",
  COURSE_ROADMAP: "COURSE_ROADMAP",
}

const modalReducer = (state, action) => {
  switch (action.type) {
    case ModalActionTypes.OPEN_MODAL:
      return {
        ...state,
        modalIsOpen: true,
        modalKey: action.payload.modalKey,
        data: action.payload.data,
      }
    case ModalActionTypes.CLOSE_MODAL:
      return {
        ...state,
        modalIsOpen: false,
        modalKey: null,
      }
    default:
      return state
  }
}

const modalInitialState = {
  modalIsOpen: false,
  modalKey: null,
}

const ModalContext = createContext({
  openModal: () => {},
  closeModal: () => {},
  modalState: modalInitialState,
  ModalActionTypes: ModalActionTypes,
  modalKeyMap,
})

export const ModalProvider = ({ children }) => {
  const [modalState, dispatch] = useReducer(modalReducer, modalInitialState)

  const openModal = payload =>
    dispatch({
      type: ModalActionTypes.OPEN_MODAL,
      payload: payload,
    })

  const closeModal = () => dispatch({ type: ModalActionTypes.CLOSE_MODAL })

  const value = useMemo(
    () => ({
      modalState,
      openModal,
      closeModal,
      ModalActionTypes,
      modalKeyMap,
    }),
    [modalState]
  )

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

export default ModalContext
