import React, { useContext } from "react"

import ModalContext from "../../providers/modal/modal.provider"
import VideoPlayer from "../video-player/video-player.component"

const Modal = () => {
  const { modalState, modalKeyMap } = useContext(ModalContext)
  const { modalIsOpen, modalKey, data } = modalState

  const modalSwitch = (key, data) => {
    switch (key) {
      case modalKeyMap.VIDEO_PLAYER:
        return <VideoPlayer {...data} />
      default:
        return null
    }
  }

  return modalIsOpen ? <>{modalSwitch(modalKey, data)}</> : null
}

export default Modal
