import styled from "styled-components"

export const ModalContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  top: 0;
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2010;
`

Overlay.displayName = "Overlay"

export const ModalContentContainer = styled.div`
  position: relative;
  width: 80vw;
  height: 65vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 45px;
  border-radius: 5px;
  background-color: black;
  z-index: 2020;
  @media screen and (max-width: 780px) {
    width: 100%;
    height: auto;
    padding: 10px;
    border-radius: 0px;
  }
`

ModalContainer.displayName = "ModalContainer"

export const CloseButton = styled.span`
  position: absolute;
  text-align: center;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  color: white;
  cursor: pointer;
  z-index: 2002;
`

CloseButton.displayName = "CloseButton"
