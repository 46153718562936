import { css } from "styled-components"

const RATIOS = {
  bold: 1.22,
  regular: 1.3,
  light: 1.45,
}

export const lineHeightCrop = (
  topCrop,
  bottomCrop,
  fontSize,
  lineHeight,
  fontWeight = "regular",
  topAdjust = 0,
  bottomAdjust = 0
) => {
  const lineHeightRatio = lineHeight / fontSize,
    lineHeightEm = RATIOS[fontWeight],
    dynamicTopCrop =
      Math.max(topCrop + (lineHeightEm - lineHeightRatio) * (fontSize / 2), 0) /
      fontSize,
    dynamicBottomCrop =
      Math.max(
        bottomCrop + (lineHeightEm - lineHeightRatio) * (fontSize / 2),
        0
      ) / fontSize

  return css`
    &::before,
    &::after {
      content: "";
      display: block;
      height: 0;
      width: 0;
    }

    &::before {
      margin-bottom: ${`calc(-${dynamicTopCrop}em + ${topAdjust}px)`};
    }

    &::after {
      margin-top: ${`calc(-${dynamicBottomCrop}em + ${bottomAdjust}px)`};
    }
  `
}
